import React from 'react';
import './Recruitment.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const JobInfo = () => {
    const navigate = useNavigate();
    const handleReturn = () => {
        navigate('/recruitment');
      };

  return (
    <div className="job-info">
      <button className="return-button" onClick={handleReturn}>
        ←
      </button>
      <h2>新卒・中途採用情報</h2>
      <h3>職種</h3>
      <ul>
        <li>システムエンジニア【業務系システム開発／組込・制御システム開発】</li>
        <li>プログラマー【業務系システム開発／組込・制御システム開発】</li>
      </ul>
      <h3>応募資格</h3>
      <ul>
        <li>大学もしくは専門学校卒業以上</li>
        <li>年齢不問</li>
        <li>【主な使用言語】</li>
        <ul>
          <li>業務系システム開発：C#、VB、Java、ASP.NET</li>
          <li>組込・制御系アプリ開発：C、C#、VC++</li>
          <li>モバイルアプリ開発：Objective-C、Java、HTML5</li>
        </ul>
        <li>※上記開発経験のある方優遇</li>
      </ul>
      <h3>勤務時間</h3>
      <p>基本時間　9：00～18：00<br />※特別な事由がない限り勤務先に準じます</p>
      <h3>ご応募・お問い合わせについて</h3>
      <p>以下をご記入の上、<Link to="/contact" className="contact-link">「お問い合せフォーム」</Link>よりご連絡ください。</p>
      <ul>
        <li>氏名</li>
        <li>フリガナ</li>
        <li>生年月日</li>
        <li>住所</li>
        <li>電話番号</li>
        <li>メールアドレス</li>
        <li>お問い合わせ内容</li>
      </ul>
    </div>
  );
};

export default JobInfo;
