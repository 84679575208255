import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="footer">
      <nav className="footer-nav">
        <ul className="footer-nav-list">
          <li className="footer-nav-item">
            <NavLink 
              exact 
              to="/" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              ホーム <span className="footer-nav-subtext">(Home)</span>
            </NavLink>
          </li>
          <li className="footer-nav-item">
            <NavLink 
              exact 
              to="/greeting" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              代表挨拶 <span className="footer-nav-subtext">(Greeting)</span>
            </NavLink>
          </li>
{/*          <li className="footer-nav-item">
            <NavLink 
              to="/business" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              業務案内 <span className="footer-nav-subtext">(Business)</span>
            </NavLink>
          </li>
*/} 
          <li className="footer-nav-item">
            <NavLink 
              to="/company" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              会社案内 <span className="footer-nav-subtext">(Company Info)</span>
            </NavLink>
          </li>
          <li className="footer-nav-item">
            <NavLink 
              to="/achievement" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              案件実績 <span className="footer-nav-subtext">(Case Study)</span>
            </NavLink>
          </li>
          <li className="footer-nav-item">
            <NavLink 
              to="/service" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              自社サービス <span className="footer-nav-subtext">(Our Services)</span>
            </NavLink>
          </li>
          <li className="footer-nav-item">
            <NavLink 
              to="/recruitment" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              採用情報 <span className="footer-nav-subtext">(Recruitment)</span>
            </NavLink>
          </li>
          <li className="footer-nav-item">
            <NavLink 
              to="/contact" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              お問い合わせ <span className="footer-nav-subtext">(Contact)</span>
            </NavLink>
            </li>
            <li className="footer-nav-item">
            <NavLink 
              to="/privacy-policy" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              プライバシーポリシー <span className="footer-nav-subtext">(Privacy Policy)</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="footer-info">
        <p className="footer-address">
          株式会社 IT plus<br/>
          〒460-0002 愛知県名古屋市中区丸の内一丁目17番19号 キリックス丸の内ビル705号<br />
          <a href="tel:+81526845742">TEL（052）684-5742</a> | <a href="fax:+81526845743">FAX（052）684-5743</a><br />
          Email: <a href="mailto:itplus@i-t-plus.co.jp">itplus@i-t-plus.co.jp</a>
        </p>
      </div>

      <p className="footer-copy">&copy; {new Date().getFullYear()} IT plus. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

/* テストページ
          <li className="footer-nav-item">
            <NavLink 
              to="/test" 
              className={({ isActive }) => isActive ? "footer-nav-link active" : "footer-nav-link"}
            >
              Test <span className="footer-nav-subtext">(Test)</span>
            </NavLink>
          </li>
*/