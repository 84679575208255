import React from 'react';
import { Link } from 'react-router-dom';
import './Recruitment.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Recruitment = () => {
  useDocumentTitle('採用情報');

  const boxes = [
    { to: "/process-info", text: "採用までの流れ", className: "box-process" },
    { to: "/job-info", text: "新卒・中途採用情報", className: "box-job" },
  ];

  return (
    <div className="recruitment-page">
      <div className="message">
        <h2>採用担当からのメッセージ</h2>
        <p>
          当社では、積極的・自発的に仕事に取り組むことができる、好奇心ある人材を求めています。
          「こんなもの作りたい！」など未来を持っている方、またそれに向かって行動できる方、少数精鋭のIT plusでその未来を実現してみませんか。
        </p>
      </div>
      <div className="content-boxes">
        {boxes.map((box, index) => (
          <Link 
            key={index} 
            to={box.to} 
            className={`box ${box.className}`}
          >
            <div className="box-content">
              <h2>{box.text}</h2>
              <div className="arrow-icon">→</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Recruitment;
