import React from 'react';
import './Recruitment.css';
import { useNavigate } from 'react-router-dom';

const ProcessInfo = () => {
    const navigate = useNavigate();

    const handleReturn = () => {
      navigate('/recruitment');
    };

  return (
    <div className="process-info">
      <button className="return-button" onClick={handleReturn}>
        ←
      </button>
      <h2>採用までの流れ</h2>
      <ol>
        <li><strong>応募</strong><p>募集要項をご確認の上、履歴書・職務経歴書を添えて人事担当宛てにご送付ください。</p></li>
        <li><strong>書類選考</strong><p>1週間以内に合否のご連絡をいたします。</p></li>
        <li><strong>面接</strong><p>職務経歴、応募動機などを伺います。また、具体的な仕事内容や条件についてもお話しします。</p></li>
        <li><strong>適性検査</strong><p>簡単な適性検査を受けていただきます。</p></li>
        <li><strong>内定</strong><p>書類選考から内定までは約1～2週間を予定しています。</p></li>
      </ol>
      <h3>応募書類の送付先</h3>
      <p>〒460-0002<br />名古屋市中区丸の内一丁目17番19号 キリックス丸の内ビル705号</p>
      <p>※お電話やEメールでのお問合せも受け付けます。<br />※応募の秘密は厳守します。<br />※面接日・入社日は相談に応じます。<br />※在職中の方もご相談ください。</p>
    </div>
  );
};

export default ProcessInfo;
