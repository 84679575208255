import React from 'react';
import './AwsPartnerAnnouncement.css'; 
import { useNavigate } from 'react-router-dom';

const AwsPartnerAnnouncement: React.FC = () => {
    const navigate = useNavigate();
    const handleReturn = () => {
        navigate('/');
      };

    return (
        <div className="aws-partner-announcement-page">
            <button className="return-button" onClick={handleReturn}>
              ←
            </button>
            <div className="announcement-header">
                <h1>AWS セレクトティアサービスパートナー 認定のお知らせ</h1>
                <img 
                    src="/images/infoaws_img1.png" 
                    alt="AWS Partner Announcement" 
                    className="aws-image"
                />
            </div>
            <div className="announcement-content">
                <p>当社は、2023年10月6日(金)、アマゾンウェブサービス（以下、AWS）のAWSパートナーネットワーク（APN）において、「AWSセレクトティアサービスパートナー」に認定されました。</p>
                <p>この度「AWSセレクトティアサービスパートナー」の認定を受けたことにより、さらに品質の高いシステム構築とプラスαのご提案で、お客様のビジネスに貢献してまいります。</p>
            </div>
            <div className="announcement-section">
                <h2>AWSパートナーネットワークとは</h2>
                <p>AWSパートナーネットワーク（以下、 APN）は、AWSがAWS上の開発や構築経験を持つ企業を対象に世界規模で展開するパートナープログラムのグローバルコミュニティです。</p>
                <p>パートナー認証を取得することで、AWSと共にお客様に革新的なソリューションの提供や技術的な課題解決をご支援することができるようになります。</p>
            </div>
            <div className="announcement-section">
                <h2>AWSセレクトティアサービスパートナーとは</h2>
                <p>AWSセレクトティアサービスパートナーとは、お客様がAWSのあらゆるサービスを最大限に活用してクラウドへの移行を迅速化するためのコンサルティングやマネージドサービスといったプロフェッショナルサービスを提供する企業のことです。</p>
                <p>認定を取得するためには、AWSのトレーニング受講者数と認定資格試験の合格者数が社内に4名以上いること、そしてAWSを活用した実績が一定数ある事が要件となります。</p>
            </div>
        </div>
    );
};

export default AwsPartnerAnnouncement;
