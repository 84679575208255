import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import StarrySky from './StarrySky';

const StarrySkyWrapper: React.FC = () => {
  const [showStarrySky, setShowStarrySky] = useState(true);
  const navigate = useNavigate();
  const hasNavigated = useRef(false);

  // 最初に開かれたときのみ星空全表示
  useEffect(() => {
    if (!hasNavigated.current) {
      const timer = setTimeout(() => {
        setShowStarrySky(false);
        navigate('/'); // ホームへ
        hasNavigated.current = true;
      }, 5000); //5秒後閉じる

      return () => clearTimeout(timer);
    }
  }, [navigate]);

  return showStarrySky ? <StarrySky /> : null;
};

export default StarrySkyWrapper;
