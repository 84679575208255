import React, { useState } from 'react';
import './InHouseService.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import DemoPopup from '../DemoPopup/DemoPopup'; 
import ImageModal from '../DemoPopup/ImageModal';

const cardsData = [
  {
    imgSrc: './images/service-item1.png',
    title: 'SPIテストサービス',
    category: '内製化',
    skill: 'AWS + Postgres + React',
    content: '独自SPIテストサービス \n 採用時に能力適正試験として実施',
    link: './images/service-demo1.mp4' //https://d2riktq2e72oeo.cloudfront.net/
  },
  {
    imgSrc: './images/service-item2.png',
    title: 'きのうなに食べた',
    category: '健康管理サービス',
    skill: 'AWS + Postgres + React + Flutter + Slack',
    content: '昨日なにを食べたか、確認・記録してくれるサービス \n 毎日の食事を記録して健康管理',
    link: 'https://d3pndn4053cvgl.cloudfront.net'
  },
  {
    imgSrc: './images/service-item3.png',
    title: 'こども、ペット自動撮影サービス',
    category: 'AI写真サービス ',
    skill: 'AWS + Postgres + React + Flutter',
    content: 'こどもやペットのかわいい瞬間を自動記録するサービス \n 撮影画像からAIでかわいい瞬間を自動判定',
    link: ''
  },
  {
    imgSrc: './images/service-item4.png',
    title: '自動散水サービス',
    category: 'IoT自動化サービス',
    skill: 'AWS + Postgres + Raspberry Pi',
    content: '植物に自動散水するサービス \n 対象の植物と天候からAIで自動判定し、散水量を調整',
    link: ''
  }
];

const InHouseService = () => {
  useDocumentTitle('自社サービス');
  const [revealedCardIndex, setRevealedCardIndex] = useState<number | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentDemoLink, setCurrentDemoLink] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImgSrc, setCurrentImgSrc] = useState('');

  const reveal = (index: number) => {
    setRevealedCardIndex(index);
  };

  const collapse = () => {
    setRevealedCardIndex(null);
  };

  const openPopup = (demoLink: string) => {
    setCurrentDemoLink(demoLink);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentDemoLink('');
  };

  const openImageModal = (imgSrc: string) => {
    setCurrentImgSrc(imgSrc);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImgSrc('');
  };

  return (
    <div className="control-pane">
      <div className='service-header'>
        <h2>自社サービス</h2>
      </div>
      <div className="control-section">
        {cardsData.map((card, index) => (
          <div key={index} className="service-col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className={`e-card-resize-container ${revealedCardIndex === index ? 'show-card' : ''}`}>
              <div className="e-card">
                <img className="img-responsive" src={card.imgSrc} alt="Card Image" onClick={() => openImageModal(card.imgSrc)} />
                
                {/* 表 */}
                <div id="card_revealed">
                  <div className="e-card-content">
                    <table>
                      <tbody>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>サービス名</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.title}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>カテゴリ</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.category}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>使ってる技術</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.skill}</div></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <button id="showcarddata" onClick={() => reveal(index)} className="e-btn e-outline e-primary">
                              Know More
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* 裏 */}
                <div id="card_reveal">
                  <div className="e-card-header">
                    <div className="e-card-header-caption">
                      <div className="e-card-header-title">{card.title}</div>
                      <button className="close-btn" onClick={collapse}>×</button>
                    </div>
                    <div id="card-reveal_collapse" onClick={collapse}>
                      <span className="e-icons e-collapse"></span>
                    </div>
                  </div>
                  <div className="e-card-content">
                    {card.content.split('\n').map((line, lineIndex) => (
                      <div key={lineIndex}>{line}</div>
                    ))}
                  </div>
                  <div className="e-card-actions">
                    <button onClick={() => openPopup(card.link)} className="e-btn e-outline e-primary">
                      Play Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* デモ画面 */}
      <DemoPopup isOpen={isPopupOpen} onClose={closePopup} videoLink={currentDemoLink} />

      {/* 画像拡大モーダル */}
      <ImageModal isOpen={isImageModalOpen} imgSrc={currentImgSrc} onClose={closeImageModal} />
    </div>
  );
};

export default InHouseService;