import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home.tsx';
import Business from './components/Business/Business';
import Company from './components/Company/Company';
import Achievement from './components/Achievement/Achievement';
import InHouseService from './components/InHouseService/InHouseService';
import Recruitment from './components/Recruitment/Recruitment';
import ProcessInfo from './components/Recruitment/ProcessInfo';
import JobInfo from './components/Recruitment/JobInfo';
import Contact from './components/Contact/Contact';
import CeoGreeting from './components/CeoGreeting/CeoGreeting';
import AwsPartnerAnnouncement from './components/Home/Announcements/AwsPartnerAnnouncement'; 
import HomepageRenewalAnnouncement from './components/Home/Announcements/HomepageRenewalAnnouncement'; 
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import StarrySkyWrapper from './components/StarrySky/StarrySkyWrapper';

import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <Router>
      <StarrySkyWrapper />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/greeting" element={<CeoGreeting />} />
        <Route path="/business" element={<Business />} />
        <Route path="/company" element={<Company />} />
        <Route path="/achievement" element={<Achievement />} />
        <Route path="/service" element={<InHouseService />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/process-info" element={<ProcessInfo />} />
        <Route path="/job-info" element={<JobInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aws-partner-announcement" element={<AwsPartnerAnnouncement />} />
        <Route path="/hp-renewal-announcement" element={<HomepageRenewalAnnouncement/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
