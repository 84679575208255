import React, { useRef, useEffect, useState } from 'react';
import './StarrySky.css';

// コメント部分のみ変更(サイズ調整のみ)

interface Star {
  x: number;
  y: number;
  size: number;
  opacity: number;
  color: string;
  delta: number;
}

interface ShootingStar {
  x: number;
  y: number;
  length: number;
  speed: number;
  angle: number;
  life: number;
}

const StarrySky: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const stars: Star[] = [];
    const shootingStars: ShootingStar[] = [];
    const starCount = 200;
  
    //大きさ調整
    const scale = window.devicePixelRatio;
    canvas.width = window.innerWidth * scale;
    canvas.height = window.innerHeight * scale;
    ctx.scale(scale, scale);

    const getRandomColor = (): string => {
      const colors = [
        'rgba(255, 255, 255,',
        'rgba(255, 223, 186,',
        'rgba(173, 216, 230,',
        'rgba(255, 182, 193,'
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    const createStar = (): Star => {
      return {
        x: Math.random() * canvas.width, // 大きさ調整
        y: Math.random() * canvas.height, // 大きさ調整
        size: Math.random() * 2 + 1,
        opacity: Math.random(),
        color: getRandomColor(),
        delta: (Math.random() * 0.01 + 0.005),
      };
    };

    for (let i = 0; i < starCount; i++) {
      stars.push(createStar());
    }

    const drawStars = () => {
      stars.forEach((star) => {
        star.opacity += star.delta;
        if (star.opacity <= 0 || star.opacity >= 1) {
          star.delta *= -1;
        }
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fillStyle = `${star.color} ${star.opacity})`;
        ctx.fill();
      });
    };

    const createShootingStar = () => {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height / 2;
      const length = Math.random() * 200 + 100;
      const speed = Math.random() * 3 + 2;
      const angle = Math.random() * Math.PI / 4 - Math.PI / 8;
      const life = Math.random() * 50 + 50;
      shootingStars.push({ x, y, length, speed, angle, life });
    };

    const drawShootingStars = () => {
      for (let i = 0; i < shootingStars.length; i++) {
        const star = shootingStars[i];
        if (star.life <= 0) {
          shootingStars.splice(i, 1);
          i--;
          continue;
        }
        ctx.beginPath();
        ctx.moveTo(star.x, star.y);
        ctx.lineTo(star.x - star.length * Math.cos(star.angle), star.y - star.length * Math.sin(star.angle));
        ctx.strokeStyle = `rgba(255, 255, 255, ${star.life / 100})`;
        ctx.lineWidth = 2;
        ctx.stroke();

        star.x += star.speed * Math.cos(star.angle);
        star.y += star.speed * Math.sin(star.angle);
        star.life -= 1;
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // 大きさ調整
      drawStars();
      drawShootingStars();

      if (Math.random() < 0.02) {
        createShootingStar();
      }

      requestAnimationFrame(animate);
    };

    animate();

    // 大きさ調整
    const resizeCanvas = () => {
      canvas.width = window.innerWidth * scale;
      canvas.height = window.innerHeight * scale;
      ctx.scale(scale, scale);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const timer = setTimeout(() => {
      setShowLogo(true);
    }, 2000); // 2秒後ロゴ表示

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      document.body.style.overflow = '';
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="starry-sky-fullscreen">
      <canvas ref={canvasRef} />
      <img src="/images/hd_logo_white.png" alt="Company Logo" className={`itp-logo ${showLogo ? 'show-logo' : ''}`} />
    </div>
  );
};

export default StarrySky;
