import React, { useRef, useEffect } from 'react';
import './StarrySky.css';

interface Star {
  x: number;
  y: number;
  size: number;
  opacity: number;
  color: string;
  delta: number;
}

interface ShootingStar {
  x: number;
  y: number;
  length: number;
  speed: number;
  angle: number;
  life: number;
}

const StarrySky: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const stars: Star[] = [];
    const shootingStars: ShootingStar[] = [];
    const starCount = 200;
    const width = canvas.width;
    const height = canvas.height;

    const getRandomColor = (): string => {
      const colors = [
        'rgba(255, 255, 255,', // White
        'rgba(255, 223, 186,', // Light orange
        'rgba(173, 216, 230,', // Light blue
        'rgba(255, 182, 193,'  // Light pink
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    const createStar = (): Star => {
      return {
        x: Math.random() * width,
        y: Math.random() * height,
        size: Math.random() * 2,
        opacity: Math.random(),
        color: getRandomColor(),
        delta: (Math.random() * 0.01 + 0.005), // Twinkle variation, slower change
      };
    };

    for (let i = 0; i < starCount; i++) {
      stars.push(createStar());
    }

    const drawStars = () => {
      stars.forEach((star) => {
        star.opacity += star.delta;
        if (star.opacity <= 0 || star.opacity >= 1) {
          star.delta *= -1; // Reverse the twinkle direction
        }
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fillStyle = `${star.color} ${star.opacity})`;
        ctx.fill();
      });
    };

    const createShootingStar = () => {
      const x = Math.random() * width;
      const y = Math.random() * height / 2; // 上半分から発生
      const length = Math.random() * 200 + 100;
      const speed = Math.random() * 3 + 2;
      const angle = Math.random() * Math.PI / 4 - Math.PI / 8; // -22.5度から22.5度の範囲
      const life = Math.random() * 50 + 50;
      shootingStars.push({ x, y, length, speed, angle, life });
    };

    const drawShootingStars = () => {
      for (let i = 0; i < shootingStars.length; i++) {
        const star = shootingStars[i];
        if (star.life <= 0) {
          shootingStars.splice(i, 1);
          i--;
          continue;
        }
        ctx.beginPath();
        ctx.moveTo(star.x, star.y);
        ctx.lineTo(star.x - star.length * Math.cos(star.angle), star.y - star.length * Math.sin(star.angle));
        ctx.strokeStyle = `rgba(255, 255, 255, ${star.life / 100})`;
        ctx.lineWidth = 2;
        ctx.stroke();

        star.x += star.speed * Math.cos(star.angle);
        star.y += star.speed * Math.sin(star.angle);
        star.life -= 1;
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, width, height);
      drawStars();
      drawShootingStars();

      if (Math.random() < 0.02) {
        createShootingStar();
      }

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <div className="starry-sky-container">
      <canvas ref={canvasRef} className="starry-sky" />
    </div>
  );
};

export default StarrySky;
