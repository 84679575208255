import React, { useState, useEffect } from 'react';
import './CeoGreeting.css';
import StarrySky from './StarrySky';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const CeoGreeting = () => {
  // タイトル
  useDocumentTitle('代表挨拶');

  const [greeting, setGreeting] = useState('');
  //textファイルから挨拶取得
  useEffect(() => {
    fetch('/Greeting.txt')
      .then(response => response.text())
      .then(text => setGreeting(text))
      .catch(error => console.error('Error loading the greeting text:', error));
  }, []);

  // テキストをフォーマット
  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="greeting-page">
      <section className="greeting">
        <h2>代表挨拶</h2>
        <div className="ceo-section">
        </div>
        <h1>ようこそ、IT plusへ</h1>
        <StarrySky />
        <div className="powered-by">
          <span>Powered by</span>
          <img src="/react-logo.svg" alt="React Logo" className="logo" />
          <img src="/aws-logo.svg" alt="AWS Logo" className="logo" />
        </div>
        <p>{formatText(greeting)}</p>
        <img src="/images/signature.png" alt="Signature" className="signature" />
      </section>
    </div>
  );
};

export default CeoGreeting;
