import React from 'react';
import './DemoPopup.css';

const DemoPopup = ({ isOpen, onClose, videoLink }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2>Playing Demo</h2>
        {videoLink ? (
          <iframe
            width="100%"
            height="80%"
            src={videoLink}
            title="Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ border: '0.8px solid gray' }} 
          ></iframe>
        ) : (
          <>
          <div className="floating-shapes">
            <div className="shape diamond" style={{ top: '10%', left: '10%', transform: 'scale(0.8)' }}></div>
            <div className="shape diamond" style={{ top: '20%', left: '80%', transform: 'scale(1.1)' }}></div>
            <div className="shape diamond" style={{ top: '70%', left: '50%', transform: 'scale(0.9)' }}></div>
            <div className="shape diamond" style={{ top: '40%', left: '10%', transform: 'scale(1)' }}></div>
            <div className="shape diamond" style={{ top: '30%', left: '90%', transform: 'scale(0.7)' }}></div>

            <div className="shape circle" style={{ top: '15%', left: '45%', transform: 'scale(1.1)' }}></div>
            <div className="shape circle" style={{ top: '50%', left: '85%', transform: 'scale(0.9)' }}></div>
            <div className="shape circle" style={{ top: '65%', left: '30%', transform: 'scale(1)' }}></div>
            <div className="shape circle" style={{ top: '80%', left: '70%', transform: 'scale(0.8)' }}></div>
            <div className="shape circle" style={{ top: '90%', left: '15%', transform: 'scale(1.2)' }}></div>
            <div className="shape circle" style={{ top: '35%', left: '75%', transform: 'scale(0.7)' }}></div>
          </div>
            <p className="preparing-text">準備中です。</p><br/>
            <p className="preparing-text">Coming Soon!</p>
          </>
        )}
      </div>
    </div>
  );
};

export default DemoPopup;
