import React from 'react';
import './Business.css';

const Business = () => {

  return (
    <div className="business-page">
      <div className="coming-soon-message">
        <h1 className="animated-text">準備中です。</h1>
        <p className="animated-text">Coming Soon</p>
      </div>
    </div>
  );
};

export default Business;
