import React, { useState, useEffect } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [policyText, setPolicyText] = useState('');

  // テキストファイルを取得
  useEffect(() => {
    fetch('/privacy-policy.txt')
      .then(response => response.text())
      .then(text => setPolicyText(text))
      .catch(error => console.error('Error loading privacy policy:', error));
  }, []);

  return (
    <div className="privacy-policy">
      <h1>個人情報保護方針</h1>
      <pre>{policyText}</pre>
    </div>
  );
};

export default PrivacyPolicy;
