import React from 'react';
import './ImageModal.css';

interface ImageModalProps {
  isOpen: boolean;
  imgSrc: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, imgSrc, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content">
        <img src={imgSrc} alt="Large Preview" className="image-modal-image" />
        <button className="close-modal-btn" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
