import React from 'react';
import './HomepageRenewalAnnouncement.css'; 
import { useNavigate } from 'react-router-dom';

const HomePageRenewal: React.FC = () => {
    const navigate = useNavigate();
    const handleReturn = () => {
        navigate('/');
      };

    return (
        <div className="hp-renewal-announcement-page">
            <button className="return-button" onClick={handleReturn}>
              ←
            </button>
            <div className="announcement-header">
                <h1>ホームページリニューアルのお知らせ</h1>
            </div>
            <div className="announcement-content">
                <p>平素より格別のご愛顧を賜り、誠にありがとうございます。この度、当社のホームページをリニューアルいたしました。</p>
                <p>今後も、より多くの皆様にご満足いただけるサービスの提供を目指し、さらに内容の充実を図ってまいりますので、引き続きご愛顧賜りますようお願い申し上げます。</p>
                <p>尚、旧ホームページは、<a className="old-hp-link" href="https://d3ez2yms3w65nx.cloudfront.net" target="_blank" rel="noopener noreferrer">こちら</a>から引き続きご閲覧頂けます。</p>
                <p>どうぞ、今後ともよろしくお願い申し上げます。</p>
                <p>令和6年 9月 吉日</p>
            </div>
        </div>
    );
};

export default HomePageRenewal;
